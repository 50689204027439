export const link = `
   contentLink {
    url
    title
    text
    target
    isElement
    element {
      id
      title
      uri
    }
    type
    ariaLabel
    isEmpty
  }
`;

export const linkContent = `
    {
        url
        title
        text
        target
        isElement
        element {
        id
        title
        uri
        }
        type
        ariaLabel
        isEmpty
    }
    `;

const icon = `
  {
      inline
   }
`;
export const navLink = `
  {
    url
    title
    text
    target
    isElement
    element {
      id
      title
      uri
    }
    type
    ariaLabel
    isEmpty
  }
`;

export const paddingSettings = `
    ... on paddingSettings_BlockType {
      id
      paddingBottom
      paddingTop
    }
`;

const richText = ` {
    typeHandle
    id
    heading
    isgray
    headingSize
    preheading
    contentText
    containerSize
    paddingSettings {
      ${paddingSettings}
    }
    ${link}
}
`;

const cookieBot = ` {
    typeHandle
    id
    heading
    isgray
    headingSize
    preheading
    plainText
    containerSize
    paddingSettings {
      ${paddingSettings}
    }
    ${link}
}
`;
export const img = ` {
    id
    width
    height
    orgUrl: url
    url(format: "webp")
    smUrl: url(height: 100, format: "webp")
    hasFocalPoint
    focalPoint
    format
    alt
    title
}
`;
export const imgFullWidth = `@transform(handle: "fullBasis") {
        id
        width
        height
        url
        hasFocalPoint
        focalPoint
        format
        alt
        title
        srcset(sizes: ["320w", "640w", "1280w", "1920w", "2560w"])
    }
`;
export const imgHalfWidth = `@transform(handle: "halfBasis") {
        id
        width
        height
        url
        hasFocalPoint
        focalPoint
        format
        alt
        title
        srcset(sizes: ["320w", "640w", "1280w", "1920w"])
    }
`;
export const imgThirdWidth = `@transform(handle: "thirdBasis") {
        id
        width
        height
        url
        hasFocalPoint
        focalPoint
        format
        alt
        title
        srcset(sizes: ["320w", "640w", "1280w", "1920w"])
    }
`;
export const imgHeroSmall = `img @transform(handle: "heroSmall") {
        id
        width
        height
        url
        hasFocalPoint
        focalPoint
        format
        alt
        title
        srcset(sizes: ["320w", "640w", "1280w", "1920w", "2560w"])
    }
`;
export const seoImg = ` {
    id
    width
    transWidth: width(transform: "metaImage")
    transHeight: height(transform: "metaImage")
    height
    orgUrl: url
    url (transform: "metaImage")
    hasFocalPoint
    focalPoint
    format
    transFormat: format(transform: "metaImage")
    alt
    title
}
`;

export const svg = ` {
    id
    width
    height
    url
    hasFocalPoint
    focalPoint
    format
}
`;

export const imageSettings = `
    ... on imageSettings_BlockType {
      fullWidth
      halfHeight
      objectFit
    }
`;

const author = `
{
  id
  img ${img}
  authorName
  authorPosition
  dateFrom
}
`;
export const referenz = `
            ... on Referenzen_BlockType {
              id
              entry {
                title
                ... on house_default_Entry {
                  id
                  uri
                  houseHeaderImage ${imgThirdWidth}
                  realEstateTypes {
                    title
                  }
                  houseStyles {
                    title
                  }
                  totalLivingArea
                  promotionalPrice
                  pricetyp1from
                  pricetyp1to
                  pricetyp2from
                  pricetyp2to
                }
              }
            }
`;
const textImage = `
{
    typeHandle
    id
    img ${imgHalfWidth}
    isgray
    headingSize
    paddingSettings {
        ${paddingSettings}
    }
    imageSettings {
        ${imageSettings}
    }
    textImage {
      ... on textImage_BlockType {
        id
        imgOverlay
        imgOverlayText
        imgPosition
      }
    }
    heading
    preheading
    contentText
    ${link}
}
`;

const compactTextImage = `
  id
  typeHandle
  heading
  isgray
  contentText
  headingSize
  paddingSettings {
      ${paddingSettings}
  }
  imageSettings {
      ${imageSettings}
  }
  img ${imgThirdWidth}
  textImage {
    ... on textImage_BlockType {
      id
      imgPosition
      imgOverlay
      imgOverlayText
    }
  }
  ${link}
`;

export const seoData = `
      metaDescription
      metaImage ${seoImg}
      metaTitle
`;

export const blocksQuery = `
        blocks {
        ... on blocks_imageText_BlockType ${textImage}
        ... on blocks_hero_BlockType {
          typeHandle
          id
          heading
          preheading
          headingSize
          isH1
          paddingSettings {
            ${paddingSettings}
          }
          ${link}
          Referenzen {
            ${referenz}
          }
          img ${imgFullWidth}
        }
        ... on blocks_uspBaner_BlockType {
          typeHandle
          id
          heading
          preheading
          contentText
          headingSize
          paddingSettings {
            ${paddingSettings}
          }
          uspItem {
            ... on uspItem_BlockType {
              id
              text
              icon ${icon}
            }
          }
        }
        ... on blocks_slider_BlockType {
          typeHandle
          id
          paddingSettings {
            ${paddingSettings}
          }
          children {
            ... on blocks_imageText_BlockType ${textImage}
          }
        }
        ... on blocks_twoColumns_BlockType {
            id
            typeHandle
            paddingSettings {
              ${paddingSettings}
            }
            containerSize
            isgray
            children {
              ... on blocks_richText_BlockType ${richText}
            }
        }
        ... on blocks_richText_BlockType ${richText}
        ... on blocks_housesSlider_BlockType {
          typeHandle
          id
          heading
          headingSize
          paddingSettings {
            ${paddingSettings}
          }
          contentText
          ${link}
          Referenzen {
            ${referenz}
          }
        }
        ... on blocks_filter_BlockType {
          typeHandle
          heading
          headingSize
          paddingSettings {
            ${paddingSettings}
          }
          video {
            url
            format
            alt
            id
            filename
          }
        }
        ... on blocks_community_BlockType {
          typeHandle
          id
          heading
          headingSize
          paddingSettings {
            ${paddingSettings}
          }
          img ${imgFullWidth}
          iconText {
            ... on iconText_BlockType {
              id
              icon ${icon}
              text
            }
          }
        }
        ... on blocks_newsletter_BlockType {
          typeHandle
          id
          heading
          headingSize
          preheading
          paddingSettings {
            ${paddingSettings}
          }
          contentText
          img ${imgFullWidth}
          iconText {
            ... on iconText_BlockType {
              id
              icon ${icon}
              text
            }
          }
        }
        ... on blocks_advisor_BlockType {
          typeHandle
          id
          heading
          headingSize
          preheading
          paddingSettings {
            ${paddingSettings}
          }
          img ${imgFullWidth}
          contentText
          iconText {
            ... on iconText_BlockType {
              id
              icon ${icon}
              text
            }
          }
        }
        ... on blocks_compactImageText_BlockType {
          ${compactTextImage}
        }
        ... on blocks_compactSlider_BlockType {
          id
          typeHandle
          paddingSettings {
            ${paddingSettings}
          }
          children {
            ... on blocks_compactImageText_BlockType {
              ${compactTextImage}
            }
          }
        }
        ... on blocks_singleImage_BlockType {
          id
          typeHandle
          paddingSettings {
            ${paddingSettings}
          }
          imageSettings {
            ${imageSettings}
          }
          img ${imgFullWidth}
          preheading
        }
        ... on blocks_images_BlockType {
          id
          typeHandle
          paddingSettings {
            ${paddingSettings}
          }
          children {
            ... on blocks_image_BlockType {
              id
              typeHandle
              img ${imgHalfWidth}
              preheading
              ${link}
              icon ${icon}
              imageSettings {
                ${imageSettings}
              }
            }
          }
        }
        ... on blocks_appointments_BlockType {
          id
          typeHandle
          itemLimit
          appointmentCategory
          appointmentFilter {
            ... on appointmentOccasions_Category {
              id
              title
            }
          }
          paddingSettings {
            ${paddingSettings}
          }
        }
        ... on blocks_quote_BlockType {
          id
          typeHandle
          heading
          headingSize
          contentText
          paddingSettings {
            ${paddingSettings}
          }
          author {
            ... on author_BlockType ${author}
          }
        }
        ... on blocks_news_BlockType {
          id
          typeHandle
          preheading
          itemLimit
          headingSize
          paddingSettings {
            ${paddingSettings}
          }
        }
        ... on blocks_houseTour_BlockType {
          id
          typeHandle
          houseTour {
            ... on houseTour_BlockType {
              id
              houseTourLink
              houseTourText
              houseTourAsset ${imgFullWidth}
            }
          }
          paddingSettings {
            ${paddingSettings}
          }
        }
        ... on blocks_logos_BlockType {
          id
          typeHandle
          heading
          headingSize
          paddingSettings {
            ${paddingSettings}
          }
          children {
            ... on blocks_logo_BlockType {
              id
              img ${svg}
              ${link}
            }
          }
        }
        ... on blocks_heroSmall_BlockType {
          id
          typeHandle
          headingSize
          paddingSettings {
            ${paddingSettings}
          }
          isH1
          ${imgHeroSmall}
          gradientBackground
          heading
        }
        ... on blocks_audio_BlockType {
          id
          typeHandle
          audioFile {
            url
          }
          paddingSettings {
            ${paddingSettings}
          }
          img ${imgHalfWidth}
          heading
          contentText
        }
        ... on blocks_youtubeId_BlockType {
          id
          typeHandle
          paddingSettings {
            ${paddingSettings}
          }
          youtubeId {
            ... on youtubeId_BlockType {
              id
              youtubeId
            }
          }
        }
        ... on blocks_cookiebot_BlockType {
          typeHandle
          id
          heading
          isgray
          headingSize
          preheading
          plainText
          containerSize
          paddingSettings {
            ${paddingSettings}
          }
        }
      }
`;

export const houseTour = `
houseTour {
    ... on houseTour_BlockType {
      id
      houseTourLink
      houseTourText
      houseTourAsset {
        url
        alt
        title
      }
    }
  }
`;

// --------------------------------------------------------------------------------

export const product = `
... on house_default_Entry {
  id
  title
  subheadline
  houseName
  videoId
  description_house
  url
  uri
  houseHeaderImage ${imgFullWidth}
  houseGallery: houseGallery ${imgFullWidth}
  homestoryHouseRelation {
    id
    title
    uri
    url
  }
  houseTypes {
    id
    title
  }
  totalLivingArea
  methodOfConstructions {
    id
    title
  }
  externalDimensionsLength
  externalDimensionsWidth
  houseStyles {
    id
    title
  }
  numberOfRooms
  facades {
    id
    title
  }
  numberOfFloors
  roofShapes {
    id
    title
  }
  energeticStandards {
    id
    title
  }
  houseTour {
    ... on houseTour_BlockType {
      id
      houseTourLink
      houseTourText
      houseTourAsset {
        url
        alt
        title
      }
    }
  }
  ${seoData}
}
`;
