<template>
  <router-view v-slot="{ Component, route }">
    <transition :name="route.meta.transition as string" mode="out-in">
      <div id="content" :key="$route.path" class="grid min-h-[50vh]">
        <Header />
        <main class="layout pt-24 w-full">
          <component :is="Component">
            <slot name="content" />
          </component>
        </main>
        <ActionBar @openOffcanvas="openProductOffcanvas = true" :auth />
        <FormOffcanvas
          v-if="openProductOffcanvas"
          @close="openProductOffcanvas = false"
          :opened="openProductOffcanvas"
        />
      </div>
    </transition>
  </router-view>
  <Footer />
  <Error />
  <slot name="errors" :snackbar="snackbar" :close="close" />
  <template v-if="openLogin.open">
    <Login
      @close="() => (openLogin.open = !openLogin.open)"
      :active="openLogin.open"
      :register="openLogin.register"
    />
  </template>
  <template v-if="openNewsletter.open">
    <NewsletterOffcanvas
      @close="() => (openNewsletter.open = !openNewsletter.open)"
      :active="openNewsletter.open"
      :email="openNewsletter.email"
    />
  </template>

  <Consultant v-if="consultant.active" />
</template>

<script setup lang="ts">
import FormOffcanvas from "@/includes/Header/components/IconsBar/FormOffcanvas.vue";

declare global {
  interface Window {
    csrfTokenName: string;
    csrfTokenValue: string;
    user: any;

    jwt: {
      jwt: string;
      jwtExpiresAt: number;
      refreshToken: string;
      refreshTokenExpiresAt: number;
    };
  }
}

// components
import Login from "./includes/Login/Login.vue";
import Consultant from "./includes/Consultant/Consultant.vue";

import ActionBar from "./includes/ActionBar/ActionBar.vue";

import { useUserStore } from "@/stores/userStore";
import userApi from "@/api/user";

// stores
import { loaded } from "./stores/loading";
import { openLogin } from "./stores/login";
import consultant from "@/stores/consultant";
import initial from "@/stores/initial";

import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import craft from "@/api/craft";
import wishlist from "./stores/wishlist";

import Header from "./includes/Header/Header.vue";
import Footer from "./includes/Footer/Footer.vue";

import Error from "./util/Error.vue";
import { openNewsletter } from "@/stores/newsletter";
import NewsletterOffcanvas from "@/components/ActionBaner/components/NewsletterOffcanvas.vue";
import index from "./router/index.ts";
import indexEN from "./router/indexEN.ts";
import routerStore from "./stores/router";
import { setSiteId } from "@/stores/siteid";

const route = useRoute();

const router = () => {
  setSiteId();
  if(window.location.pathname.includes("/eng") || window.location.hostname.endsWith("en")){
    routerStore.value = indexEN;
    return indexEN;
  } else {
    routerStore.value = index;
    return index;
  }
}

const user = useUserStore();

// controls for the error message
const snackbar = ref(true);

const openProductOffcanvas = ref(false);

const close = () => {
  snackbar.value = false;
};

const props = defineProps<{
  csrfname: string;
  csrftoken: string;
  list: number;
  auth: number;
}>();

if (!!props.auth) {
  // currentUser.value = {
  //     id: props.auth,
  //     active: !!props.auth,
  // }
}

onMounted(() => {
  userApi.GetMe().then((res) => {
    if (res) {
      if (res.success) {
        user.setUser(res.user);
      }
    }
  });

  if (props.list) {
    if (props.list != 0) {
      craft.getWishlistItems(props.list).then((res) => {
        if (res) {
          if (res.data && res.data.wishlistItems.length > 0) {
            let items = res.data.wishlistItems
              .filter((x) => x.element != null)
              .map((item: any) => {
                if (item.element) return item.element.id;
              });
            if (items.length > 0) {
              wishlist.value = {
                wishlistId: props.list,
                wishlistItems: items,
              };
            }
          }
        }
      });
    }
  }
});

// check if the login should be opened
router().isReady().then(() => {
  console.log("READY");
  if ("activeLogin" in route.query) {
    if (route.query.activeLogin == "true") {
      openLogin.value.open = true;

      router().replace({ query: null });
    }
  }
  router().afterEach((to, from) => {
    const toDepth = to.path.split("/").length;
    const fromDepth = from.path.split("/").length;
    // if we are going to the home page, we want to slide right

    if (to.fullPath.includes("/user/") || !to.name) return false;

    if (from.name != to.name) {
      initial.value = false;
    } else if (from.name == to.name && from.path != to.path) {
      initial.value = false;
    }

    if (to.name === "Home") {
      to.meta.transition = "slide-right";
    } else {
      // if we are going deeper into the app, we want to slide left
      if (toDepth == fromDepth) {
        to.meta.transition = "fade-in";
      } else if (toDepth > fromDepth) {
        to.meta.transition = "slide-left";
      } else {
        to.meta.transition = "slide-right";
      }
    }
  });
});

// watch(
//     () => loaded.value.active,
//     () => {
//         if (!loaded.value.active) {
//             const el = document.body.querySelector("#loading") as HTMLElement;
//             el.style.opacity = "0";

//             setTimeout(() => {
//                 el.remove();
//             }, 300);
//         }
//     },
// );

// watch(
//     () => route.name,
//     () => {
//       // scroll to top when animation is finished
//       setTimeout(() => {
//         window.scrollTo(0, 0);
//       }, 400);
//     },
// );
</script>

<style lang="css">
.slide-right-leave-active,
.slide-left-leave-active {
  transition: transform 0.4s ease-in-out, opacity 0.2s ease;
}

.slide-right-enter-active,
.slide-left-enter-active {
  transition: opacity 0.2s;
}

.slide-right-enter-from,
.slide-left-enter-from {
  opacity: 0;
}

.slide-right-leave-to {
  transform: translateX(100%);
  opacity: 0.9;
}

.slide-left-leave-to {
  transform: translateX(-100%);
  opacity: 0.9;
}
</style>
