<template>
  <Offcanvas
    @close="consultant.active = false"
    position="right"
    :active="consultant.active"
  >
    <template #content="{ close }">
      <div
        ref="wrapper"
        class="relative block w-screen md:w-[50vw] md:max-w-[700px] bg-gray z-10 py-4 px-4 md:px-8 h-screen !h-[100dvh] space-y-8 md:rounded-l overflow-y-scroll"
      >
        <button @click="close()" class="w-6 absolute top-6 right-6 lg:top-12 lg:right-12">
          <XMarkIcon class="w-full" />
        </button>
        <div id="contact-form" class="block">
          <h2 v-if="consultant.type == 'Vor Ort'" class="text-primary h2-s pr-11">
            Beratungstermin vor Ort vereinbaren
          </h2>
          <h2 v-else-if="consultant.type == 'Online'" class="text-primary h2-s pr-11">
            Online-Video-Beratung vereinbaren
          </h2>
          <h2 v-else class="text-primary h2-s pr-11">Informationen anfordern</h2>
          <p>
            Vielen Dank für Ihr Interesse. Der für Sie zuständige Fachberater wird dann in
            Kürze <b>ganz unverbindlich</b> Kontakt zu Ihnen aufnehmen.
          </p>
          <form
            class="space-y-6"
            @submit="handleSubmit"
            method="post"
            accept-charset="UTF-8"
          >
            <input type="hidden" name="action" value="contact-form/send" />
            <input type="hidden" :name="csrfName" :value="csrfToken" />
            <input type="hidden" name="countryId" v-model="data.honeypot" />

            <!-- pass consultant id, termin type and house style -->
            <input type="hidden" name="consultantId" :value="consultant.id" />
            <input
              v-if="consultant.type"
              type="hidden"
              name="message[terminType]"
              :value="consultant.type"
            />
            <input
              v-if="consultant.houseStyle"
              type="hidden"
              name="houseStyle"
              :value="consultant.houseStyle"
            />
            <div>
              <h3>Ihre Kontaktdaten</h3>
              <div
                style="--gap: 1.25rem"
                class="py-6 flex flex-wrap gap-[var(--gap)] minimal-input"
              >
                <SmallSelect
                  class="w-full sm:w-[40%]"
                  :rules="[rules.required]"
                  name="message[Anrede]"
                  @update="data.salutation = $event"
                  :items="['Herr', 'Frau', 'Divers']"
                  label="Anrede*"
                />
                <div class="flex w-full flex-wrap gap-[var(--gap)] minimal-input">
                  <div
                    class="w-full sm:w-[calc(50%-var(--gap))] md:w-full lg:w-[calc(50%-var(--gap))]"
                  >
                    <v-text-field
                      density="comfortable"
                      single-line
                      name="message[Vorname]"
                      v-model="data.firstName"
                      :rules="[rules.required]"
                      label="Vorname*"
                    />
                  </div>
                  <div class="w-full sm:w-1/2 md:w-full lg:w-1/2">
                    <v-text-field
                      density="comfortable"
                      single-line
                      name="message[Nachname]"
                      v-model="data.lastName"
                      :rules="[rules.required]"
                      label="Nachname*"
                    />
                  </div>
                  <div
                    class="w-full sm:w-[calc(50%-var(--gap))] md:w-full lg:w-[calc(50%-var(--gap))]"
                  >
                    <v-text-field
                      density="comfortable"
                      single-line
                      name="message[Phone]"
                      v-model="data.phone"
                      label="Telefonnummer"
                      type="phone"
                    />
                  </div>
                  <div class="w-full sm:w-1/2 md:w-full lg:w-1/2">
                    <v-text-field
                      density="comfortable"
                      single-line
                      name="fromEmail"
                      v-model="data.email"
                      :rules="[rules.required]"
                      type="email"
                      label="E-Mail*"
                    />
                  </div>
                  <div class="w-full flex-grow">
                    <v-text-field
                      density="comfortable"
                      single-line
                      v-model="data.street"
                      name="message[Street]"
                      label="Straße und Hausnummer"
                    />
                  </div>
                  <div class="w-[calc(33%-var(--gap))] flex-grow">
                    <v-text-field
                      density="comfortable"
                      single-line
                      name="message[Zip]"
                      v-model="data.zip"
                      :rules="[rules.required]"
                      label="PLZ*"
                    />
                  </div>
                  <div class="w-[calc(66%-var(--gap))] flex-grow">
                    <v-text-field
                      density="comfortable"
                      single-line
                      name="message[City]"
                      v-model="data.city"
                      :rules="[rules.required]"
                      label="Ort*"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h3>Ihre Bemerkungen</h3>
              <div class="pt-6">
                <v-textarea
                  name="message[body]"
                  v-model="data.body"
                  placeholder="Bemerkungen..."
                ></v-textarea>
              </div>
            </div>
            <div class="!mt-0 flex">
              <v-checkbox
                color="primary"
                class="!flex-shrink-0 [&_*]:w-max items-start"
                v-model="data.consent"
                :rules="[rules.required]"
              />
              <p class="content text-xs pt-3 pl-1">
                Hiermit willige ich ein, dass meine Daten zur Bearbeitung meines Anliegens
                von der Otto Baukmeier Holzbau - Fertigbau GmbH & Co KG und deren
                Vertriebsbeauftragten verwendet und gespeichert werden dürfen. Die
                Kontaktaufnahme kann über die von mir angegebenen Kontaktwege erfolgen.
                Die Informationen zu meinen sonstigen Rechten im Zusammenhang mit der
                Nutzung meiner personenbezogenen Daten habe ich in der
                <a class="hover:text-primary" href="/datenschutz" target="_blank"
                  >Datenschutzerklärung</a
                >
                zur Kenntnis genommen.<span class="text-primary">*</span>
              </p>
            </div>
            <div>
              <v-btn
                type="submit"
                :disabled="
                  !data.salutation ||
                  !data.firstName ||
                  !data.lastName ||
                  !data.zip ||
                  !data.city ||
                  !data.consent
                "
                >Jetzt Informationen anfordern</v-btn
              >
            </div>
          </form>
        </div>
        <div id="contact-success" class="py-4 hidden">
          <h3 class="h2-s text-primary">Vielen Dank für Ihre Kontaktanfrage!</h3>
          <p class="font-bold">
            Ihr Berater wird sich in Kürze mit Ihnen in Verbindung setzen.
          </p>
        </div>
        <div id="contact-failed" class="py-4 hidden">
          <h3 class="h2-s text-primary">Kontaktanfrage hat nicht geklappt!</h3>
          <p class="font-bold">Bei der Kontaktanfrage ist schiefgelaufen:</p>
          <p id="contact--error" class="content py-4"></p>
          <v-btn @click="handleBackButton" color="primary" dark rounded class="!min-w-max"
            >Zurück</v-btn
          >
        </div>
      </div>
    </template>
  </Offcanvas>
</template>

<script setup lang="ts">
import consultant from "@/stores/consultant";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import Offcanvas from "@/snippets/Offcanvas.vue";
import client from "@/api/craft";
import { ref, onMounted } from "vue";

const data = ref({
  salutation: "",
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  street: "",
  zip: "",
  city: "",
  body: "",
  honeypot: "",
  consent: false,
});

const csrfName = ref("");
const csrfToken = ref("");

const now = new Date();

const today = now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();

async function handleSubmit(e: any) {
  e.preventDefault();
  client
    .handleSubmitForm(e.target, "contact-form/send")
    .then((res) => {
      if (
        res.modelName == "submission" &&
        res.submission &&
        res.submission["fromEmail"]
      ) {
        document.getElementById("contact-form")?.classList.add("hidden");
        document.getElementById("contact-success")?.classList.remove("hidden");
      } else {
        document.getElementById("contact-form")?.classList.add("hidden");
        document.getElementById("contact-failed")?.classList.remove("hidden");
      }
    })
    .catch((err) => {
      console.log(err);
      document.getElementById("contact-form")?.classList.add("hidden");
      document.getElementById("contact-failed")?.classList.remove("hidden");
    });
}

function handleBackButton() {
  document.getElementById("contact-failed")?.classList.add("hidden");
  document.getElementById("contact-form")?.classList.remove("hidden");
}

const rules = {
  required: (value) => !!value || "Pflichtfeld",
};

onMounted(() => {
  csrfName.value = window.csrfTokenName;
  csrfToken.value = window.csrfTokenValue;
});
</script>
