import type {ConsultantSearch, WishlistData, WishlistHousesData, appointmentOccasions} from "@/types";
import {
    blocksQuery,
    img,
    imgFullWidth,
    imgThirdWidth,
    imgHeroSmall,
    linkContent,
    paddingSettings,
    link,
    referenz,
    navLink,
    product,
    seoData,
    seoImg
} from "../queries";
import {consultant} from "../queries/consultant";

import {getAll, getFiltered, GetOptions} from "../queries/filter";
import {getSiteId, setSiteId} from "@/stores/siteid";
import {footerQuery} from "../queries/footer";
import { newsletterQuery } from "../queries/newsletter";

import errorStore from "@/stores/error";

setSiteId();
const siteId = getSiteId();

const firstElementQuery =
    `blocks {
        ... on blocks_hero_BlockType {
          typeHandle
          id
          heading
          preheading
          headingSize
          isH1
          paddingSettings {
            ${paddingSettings}
          }
          ${link}
          Referenzen {
            ${referenz}
          }
          img ${imgFullWidth}
        }
        ... on blocks_heroSmall_BlockType {
          id
          typeHandle
          headingSize
          paddingSettings {
            ${paddingSettings}
          }
          isH1
          ${imgHeroSmall}
          gradientBackground
          heading
        }
    }
    ${seoData}`;


type NewsletterParams = {
    salutation: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    zip: string;
    city: string;
    honeypot: string;
};
type ContactParams = {
    salutation: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    street: string;
    zip: string;
    city: string;
    body: string;
    honeypot: string;
};

/*
    Search those themes:

    - Pages
    - Newsletter
    - Wishlist
    - Filter
    - Footer
    - User
    - Newsletter
    - Form
    - Consultant
*/
export class craft {
    token: string;
    endpoint: string;
    csrfName: string | null = null;
    csrfToken: string | null = null;

    async FetchData(query: Object, headers: HeadersInit) {
        const urlParams = new URLSearchParams(window.location.search);

        const previewToken = urlParams.get("x-craft-live-preview");
        const craftToken = urlParams.get("token");
        const fetchOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.token}`,
                ...headers
            },
            body: JSON.stringify(query),
        };

        try {
            const response = await fetch(this.endpoint + (previewToken && craftToken ? '?' + urlParams.toString() : ''), fetchOptions);;
            return await response.json();
        } catch (error) {
            console.error(error);
        }
    }

    /*
    Pages API
    --------------------------------------------------------------------------------
    */

    public async GetHome() {
        const query = `
            query GetHome($siteId: [QueryArgument]) {
              starseiteEntries(siteId: $siteId) {
                ... on starseite_starseite_Entry {
                  id
                  ${blocksQuery}
                }
              }
            }
        `;
        const variables = {
            siteId: siteId,
        };

        return this.FetchData({query, variables}, {});
    }
    public async GetHousefinder() {
        const query = `
            query GetHousefinder {
              entries(slug: "hausfinder") {
                ... on hausfinder_hausfinder_Entry {
                  id
                  title
                  heading
                  img ${imgFullWidth}
                  ${seoData}
                }
              }
            }
        `;

        return this.FetchData({query}, {});
    }

    public async GetNewsListing(limit: number = 100) {
        const query = `
    query GetNews($siteId: [QueryArgument]) {
      entries(section: "news", orderBy: "postDate DESC", limit: ${limit}, siteId: $siteId) {
          ... on news_default_Entry {
            id
            title
            postDate
            teaserText
            teaserImage ${imgThirdWidth}
            imageDarkening
            url
            uri
            ${blocksQuery}
            ${seoData}
          }
        }
      }
    
    `;
        const variables = {
            siteId: siteId,
        };
        return this.FetchData({query, variables}, {});
    }


    public async GetAppointmentListing(limit: number = 100, appointmentCategory?: string,  appointmentOccasions?: appointmentOccasions[]) {
        let nowDate = new Date();
        const query = `
    query GetAppointments($siteId: [QueryArgument]) {
      entries(section: "appointments", siteId: $siteId, orderBy:"begin", end: "> ${nowDate.toISOString()}", limit: ${limit}, ${appointmentOccasions && appointmentOccasions.length > 0 ? `relatedToCategories: {id: [${appointmentOccasions.map((occasion) => '"'+occasion.id+'"').join(",")}] },` : ''} ${appointmentCategory ? `appointmentCategory: "${appointmentCategory}"` : ''}) {
          ... on appointments_default_Entry {
            id
            title
            appointmentDescription
            begin
            end
            appointmentLink {
              linkValue
              linkText
            }
            url
            uri
            appointmentCategory
            appointmentImage ${imgThirdWidth}  
            imageDarkening
            appointmentOccasions {
            ... on appointmentOccasions_Category {
                id
                title
              }
            }
            relationConsultantAppointment {
              ... on consultant_default_Entry {
                id
                title
                phoneNumber
                mobilePhone
                title_consultant
                consultantPageLink  {
                    url
                    title
                    typeHandle
                }
              }
            }
            relationAppointmentTeams {
              ... on team_default_Entry {
                id
                title
                phoneNumber
                emailAddress
              }
            }
            ${seoData}
          }
        }
      }
    `;
        const variables = {
            siteId: siteId,
        };
        return this.FetchData({query, variables}, {});
    }

    public async GetFirstElement(handle: string, section: string) {
        let queryHead =
            `query GetFirstElement($slug: [String], $siteId: [QueryArgument]) {
              entries(slug: $slug, siteId: $siteId) {
            `;

        let queryBody = '';
        switch(section) {
            case 'Home': queryBody =
                `... on starseite_starseite_Entry {
                  id
                  ${firstElementQuery}
                }`;
                break;
            case 'Appointment': queryBody =
                `... on appointments_default_Entry {
                 id
                 ${firstElementQuery}
                }`;
                break;
            case 'News': queryBody =
                `... on news_default_Entry {
                  id
                  ${firstElementQuery}
                }`;
                break;
            case 'Page': queryBody =
                `... on pages_default_Entry {
                  id
                  ${firstElementQuery}
                }`;
                break;
            default: queryBody =
                `... on audioThemenkanaele_default_Entry {
                  id
                  ${firstElementQuery}
                }
                ... on pages_default_Entry {
                  id
                  ${firstElementQuery}
                }
                ... on starseite_starseite_Entry {
                  id
                  ${firstElementQuery}
                }
                ... on homestories_default_Entry {
                  id
                  ${firstElementQuery}
                }
                ... on news_default_Entry {
                  id
                  ${firstElementQuery}
                }
                ... on appointments_default_Entry {
                  id
                 ${firstElementQuery}
                }`;
                break;
        }
        const variables = {
            slug: [handle],
            siteId: siteId,
        };
        let query = queryHead + queryBody + `}}`;
        return this.FetchData({query, variables}, {});
    }


    public async GetPageByHandle(handle: string, section: string) {
        let queryHead =
            `query GetPageBySlug($slug: [String], $siteId: [QueryArgument]) {
              entries(slug: $slug, siteId: $siteId) {
            `;

        let queryBody = '';
        switch(section) {
            case 'Home': queryBody =
                `... on starseite_starseite_Entry {
                  id
                  ${blocksQuery}
                  ${seoData} 
                }`;
                break;
            case 'Appointment': queryBody =
                `... on appointments_default_Entry {
                  id
                  title
                  begin
                  end
                  appointmentDescription
                   address {
                    ... on address_BlockType {
                      id
                      location
                      country
                      postalCode
                      phoneNumber
                    }
                  }
                  appointmentLink ${navLink}
                  mailSubject: appointmentLink {
                    ... on appointmentLink_Email_LinkType {
                    mailSubject
                    }
                  }
                  appointmentOccasions {
                    ... on appointmentOccasions_Category {
                      id
                      groupHandle
                      slug
                      title
                    }
                  }
                  appointmentImage ${imgThirdWidth}
                  ${blocksQuery}
                  ${seoData}
                }`;
                break;
            case 'News': queryBody =
                `... on news_default_Entry {
                  id
                  title
                  teaserText
                  postDate
                  ${blocksQuery}
                  ${seoData}
                }`;
                break;
            case 'Page': queryBody =
                `... on pages_default_Entry {
                  id
                  ${blocksQuery}
                  ${seoData}
                }`;
                break;
            default: queryBody =
                `... on audioThemenkanaele_default_Entry {
                  id
                  ${blocksQuery}
                  ${seoData}
                }
                ... on pages_default_Entry {
                  id
                  ${blocksQuery}
                  ${seoData}
                }
                ... on starseite_starseite_Entry {
                  id
                  ${blocksQuery}
                  ${seoData} 
                }
                ... on homestories_default_Entry {
                  id
                  ${blocksQuery}
                  ${seoData}
                }
                ... on news_default_Entry {
                  id
                  title
                  teaserText
                  postDate
                  ${blocksQuery}
                  ${seoData}
                }
                ... on appointments_default_Entry {
                  id
                  title
                  begin
                  end
                  appointmentDescription
                   address {
                    ... on address_BlockType {
                      id
                      location
                      country
                      postalCode
                      phoneNumber
                    }
                  }
                  appointmentLink ${navLink}
                  mailSubject: appointmentLink {
                    ... on appointmentLink_Email_LinkType {
                    mailSubject
                    }
                  }
                  appointmentOccasions {
                    ... on appointmentOccasions_Category {
                      id
                      groupHandle
                      slug
                      title
                    }
                  }
                  appointmentImage ${imgThirdWidth}
                  ${blocksQuery}
                  ${seoData}
                }`;
                break;
        }

        const variables = {
            slug: [handle],
            siteId: siteId,
        };
        let query = queryHead + queryBody + `}}`;
        return this.FetchData({query, variables}, {});
    }

    public async GetNavigation() {
        const query = `
        query GetNavigation($siteId: [QueryArgument]) {
          globalSet(handle: "navigation", siteId: $siteId) {
            ... on navigation_GlobalSet {
              id
              name
              navigation {
                ... on navigation_firstLevelLink_BlockType {
                  id
                  heading
                  navLink ${navLink}
                  children {
                    ... on navigation_secondLevelLink_BlockType {
                      id
                      heading
                      navLink ${navLink}
                    }
                  }
                }
              }
              statikLinks {
                ... on statikLinks_statischeLinks_BlockType {
                  id
                  heading
                  typeHandle
                  navLink ${navLink}
                }
                ... on statikLinks_statischerText_BlockType {
                  id
                  plainText
                  plainTextKey
                  typeHandle
                }
              }
            }
          }
        }
        `;
        const variables = {
            siteId: siteId,
        };
        return this.FetchData({query, variables}, {});
    }

    public async GetUser(uid: string) {
        const query = `
      query Getuser($uid: String) {
        user(uid: $uid) {
          name
        }
      }
      `;
        const variables = {
            uid,
        };
    }

    public async GetHouse(slug: string) {
        const query = `
      query GetHouse($slug: [String], $siteId: [QueryArgument]) {
        houseEntries(slug: $slug, siteId: $siteId) {
          ${product}
        }
      }
      `;
        const variables = {
            slug: [slug],
            siteId: siteId,
        };

        return this.FetchData({query, variables}, {});
    }

    /*
    Newsletter Page API
    --------------------------------------------------------------------------------
    */

    public async GetNewsletterPage() {
      const query = newsletterQuery;

      return this.FetchData({query}, {});
  }

    /*
    Wishlist API
    --------------------------------------------------------------------------------
    */

    public async AddToWihlist(id: string) {
        let payload = new FormData();

        payload.append("action", "wishlist/items/add");
        payload.append("elementId", id.toString());

        if (!this.csrfName || !this.csrfToken) {
            if (window.csrfTokenName && window.csrfTokenValue) {
                this.csrfName = window.csrfTokenName;
                this.csrfToken = window.csrfTokenValue;
            }
        }

        payload.append(this.csrfName, this.csrfToken);

        try {
            return fetch("/", {
                method: "POST",
                body: payload,
                headers: {
                    Accept: "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    return data;
                });
        } catch (e) {
            console.error(e);
        }
    }

    public async RemoveFromWihlist(id: string) {
        let payload = new FormData();

        payload.append("action", "wishlist/items/remove");
        payload.append("elementId", id.toString());

        if (!this.csrfName || !this.csrfToken) {
            if (window.csrfTokenName && window.csrfTokenValue) {
                this.csrfName = window.csrfTokenName;
                this.csrfToken = window.csrfTokenValue;
            }
        }

        payload.append(this.csrfName, this.csrfToken);

        try {
            return fetch("/", {
                method: "POST",
                body: payload,
                headers: {
                    Accept: "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    return data;
                });
        } catch (e) {
            console.error(e);
        }
    }

    async getWishlistItems(id: number) {
        const query = `
    query MyQuery($id: [QueryArgument]) {
        wishlistItems(listId: $id) {
          element {
            ... on house_default_Entry {
              ${product}
            }
          }
        }
      }
    `;

        const variables = {
            id: [id],
        };
        const fetchOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.token}`,
            },
            body: JSON.stringify({query, variables}),
        };

        try {
            const response = await fetch(location.origin + "/api", fetchOptions);
            const data = await response.json();
            return data as WishlistData;
        } catch (error) {
            console.error(error);
        }
    }

    async getWishlistItemsByIds(id: string[]) {
        const query = `
    query MyQuery($id: [QueryArgument]) {
      houseEntries(id: $id) {
            ... on house_default_Entry {
              ${product}
            }
        }
      }
    `;

        const variables = {
            id,
        };
        const fetchOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.token}`,
            },
            body: JSON.stringify({query, variables}),
        };

        try {
            const response = await fetch(location.origin + "/api", fetchOptions);
            const data = await response.json();
            return data as WishlistHousesData;
        } catch (error) {
            console.error(error);
        }
    }

    public async GetWishlistPage() {
        const query = `
      query GetWishlistPage($siteId: [QueryArgument]) {
        entries(slug: "wishlist", siteId: $siteId) {
            ... on wishlist_wishlist_Entry {
              id
              ${seoData}
            }
        }
      }
      `;

        const variables = {
            siteId: siteId,
        }

        return this.FetchData({query, variables}, {});
    }

    /*
    Newsletter API
    --------------------------------------------------------------------------------
    */

    public async SubscribeToNewsletter(data: NewsletterParams) {
        let payload = data;
        if (!this.csrfName || !this.csrfToken) {
            if (window.csrfTokenName && window.csrfTokenValue) {
                this.csrfName = window.csrfTokenName;
                this.csrfToken = window.csrfTokenValue;
            }
        }
        payload[this.csrfName] = this.csrfToken;

        const fetchOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload),
        };

        try {
            fetch(
                "/actions/_cleverreach/newsletter-registration/submit",
                fetchOptions,
            )
                .then((res) => res.json())
                .then((data) => {
                    if (data.success) {
                        errorStore.value.active = true;
                        errorStore.value.message = "Bitte bestätigen Sie Ihre Anmeldung durch Klick auf den Link in der zugesendeten E-Mail";

                        document.getElementById("newsletter-form").classList.toggle("!hidden");
                        document.getElementById("newsletter-success").classList.toggle("hidden");

                    } else {
                        document.getElementById("newsletter-form").classList.toggle("!hidden");
                        document.getElementById("newsletter-failed").classList.toggle("hidden");
                        let errorString = '';
                        if(Object.values(data.errors).length > 0) {
                            Object.values(data.errors).forEach((error) => {
                                errorString += '<li>' + error + '</li>' ;
                            });
                        }
                        console.log(errorString);
                        document.getElementById("newsletter--error").innerHTML = '<ul>' + errorString + '</ul>';
                    }
                });
        } catch (e) {
            errorStore.value.active = true;
            errorStore.value.message = e.message;
            console.log(errorStore.value.message);
        }
    }

    /*
    Filter API
    --------------------------------------------------------------------------------
    */

    public async GetAll(filter: string = "") {
        const query = getAll(filter);
        const fetchOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.token}`,
            },
            body: JSON.stringify({query}),
        };

        const variables = {
            siteId: siteId,
        };
        return this.FetchData({query, variables}, {});
    }

    public async Pagination(filter: string, limit: number, offset: number) {
        const query = getFiltered(filter, limit, offset);
        const variables = {
            limit,
            offset,
            siteId: siteId,
        };
        return this.FetchData({query, variables}, {});
    }

    public async GetOptions() {
        const query = GetOptions;

        return this.FetchData({query}, {});
    }

    public async GetFilteredData(filter: string) {
        const query = getAll(filter);
        const variables = {
            siteId: siteId,
        };
        return this.FetchData({query, variables}, {});
    }

    /*
    Footer API
    --------------------------------------------------------------------------------
    */

    public async GetFooter() {
        const query = footerQuery;

        const variables = {
            siteId: siteId,
        };
        return this.FetchData({query, variables}, {});
    }


    /*
    Form API
    --------------------------------------------------------------------------------
    */

    public async handleSubmitForm(form: HTMLFormElement, action: string) {
        const formData = new FormData(form);

        const fetchOptions = {
            method: "POST",
            headers: {
                "Accept": "application/json"
            },
            body: formData,
        };

        try {
            // Return the promise from the fetch function
            return fetch("/", fetchOptions)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.statusText);
                    }
                    return response.json(); // This returns a promise
                });
        } catch (e) {
            console.error(e);
            return Promise.reject(e);
        }
    }

    /*
    Consultant API
    --------------------------------------------------------------------------------
    */

    public async GetDefaultConsultant(slug: string) {
        const query = consultant;
        const variables = {
            slug: [slug],
            siteId: siteId,
        };

        return this.FetchData({query, variables}, {});
    }

    public async GetConsultant(zipCode: string, houseStyle: string, callback?: (consultant?: ConsultantSearch) => void) {
        try {
            fetch('/actions/_data-sync/consultant-finder/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    zip: zipCode,
                    houseStyle: houseStyle,
                }),

            }).then((res) => res.json() as Promise<ConsultantSearch>)
                .then((res: any) => {
                    callback && callback(res);
                }).catch((err) => {
                console.log(err)
            })
        } catch (e) {
            console.error(e);
        }
    }


    /*
    Search API
    --------------------------------------------------------------------------------
    */
    public async getSearchResults(search: string) {
        const query = `
        query SearchResults($search: String, $siteId: [QueryArgument]) {
          entries(search: $search, siteId: $siteId) {
            ... on pages_default_Entry {
              id
              metaDescription
              title
              url
            }
          }
        }
        `;
        const variables = {
            search,
            siteId: siteId,
        };

        return this.FetchData({query, variables}, {});
    }
    public async getSearchPageData() {
        const query = `
    query SearchPage($siteId: [QueryArgument]) {
      entries(slug: "search", siteId: $siteId) {
        ... on searchPage_searchPage_Entry {
          id
          heading
          img ${imgFullWidth}
          ${seoData}
        }
      }
    }
    `;

    const variables = {
        siteId: siteId,
    };

    return this.FetchData({query, variables}, {});
    }

    /*
    General Globals API
    --------------------------------------------------------------------------------
    */
    public async GetGeneralGlobals() {
        const query = `
          query GetGeneralGlobals($siteId: [QueryArgument]) {
           globalSet(handle: "general", siteId: $siteId) {
            ... on general_GlobalSet {
              id
              metaImage ${seoImg}
            }
          }
        }
    `;

    const variables = {
        siteId: siteId,
    };
    return this.FetchData({query, variables}, {});
    }
}
