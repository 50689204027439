<template>
    <div id="iconbarMenu"
        :class="`hidden fixed md:block top-[114px] translate-y-[calc(-100%_-_1rem)] right-[var(--padding-x-lg)] xl:right-[calc(var(--padding-x-lg)_*_2)] bg-white rounded-full transition-[min-width] duration-300 overflow-hidden h-10 ${scrolled && !active ? 'min-w-10' : `${auth ? 'min-w-[260px]' : 'min-w-[216px]'} ` +  'px-4' }`">
        <div
            :class="`w-[600px] absolute top-0 flex gap-5 items-center justify-end h-full right-[calc((2.5rem_-_2rem)_/_2)]`">
            <ul :class="`gap-5 flex items-center `">
                <!-- :class="`${scrolled ? 'translate-x-20 opacity-0 absolute left-0' : 'translate-x-0 opacity-100'} transition-all duration-300`" -->
                <li v-if="active">
                    <button @click="active = false" type="button"
                        :class="`v-btn v-btn--rounded w-8 aspect-square bg-dark text-white grid place-content-center transition-opacity ${scrolled ? 'opacity-100' : 'opacity-0'} `" aria-label="Iconbar zumachen">
                        <MinusIcon class="w-5 stroke-2" />
                    </button>
                </li>
                <v-tooltip location="top" text="Hausfinder">
                  <template v-slot:activator="{ props }">
                      <li v-bind="props"  class="hover:scale-110">
                          <router-link to="/hausfinder" aria-label="Zum haus-sinder" :class="`transition-opacity duration-300 ${!scrolled || active ? 'opacity-100' : 'opacity-0'}`">
                            <Hause class="w-5" />
                          </router-link>
                      </li>
                  </template>
                </v-tooltip>
                <v-tooltip location="top" text="Katalog" v-if="isLoggedIn">
                    <template v-slot:activator="{ props }">
                        <li v-bind="props" class="hover:scale-110">
                            <router-link to="/user/downloads" aria-label="dokumente"
                                :class="`transition-opacity duration-300 ${!scrolled || active ? 'opacity-100' : 'opacity-0'}`">
                                <BookOpenIcon class="w-6 stroke-2" />
                            </router-link>
                        </li>
                    </template>
                </v-tooltip>
                <v-tooltip location="top" text="Merkliste">
                    <template v-slot:activator="{ props }">
                        <li v-bind="props" :data-items-length="wishlist.wishlistItems.length"  class="hover:scale-110"
                            :class="`relative transition-opacity duration-300 ${!scrolled || active ? 'opacity-100' : 'opacity-0'} ${wishlist.wishlistItems.length > 0 ? 'after:absolute after:w-4 after:grid after:place-content-center after:aspect-square after:bg-black after:top-[-32%] after:right-[-50%] after:rounded-full after:content-[attr(data-items-length)] after:text-white after:text-xs' : ''}`">
                            <router-link to="/wishlist" aria-label="merkliste">
                                <Heart class="w-6 stroke-2" />
                            </router-link>
                        </li>
                    </template>
                </v-tooltip>
                <v-tooltip location="top" :text="auth ? 'Kontobereich' : 'Anmelden'">
                    <template v-slot:activator="{ props }">
                        <li v-bind="props" class="hover:scale-110" >
                            <router-link v-if="auth" :to="auth ? '/user' : '/user/login'" aria-label="Zum Konto">
                                <Profile class="w-5" />
                            </router-link>
                            <button class="block" v-else @click="openLogin.open = true" aria-label="Anmelden">
                                <Profile class="w-5" />
                                <span class="hidden">Konto</span>
                            </button>
                        </li>
                    </template>
                </v-tooltip>
            </ul>
            <div :class="`bg-gray w-px h-5 hidden transition-opacity`"></div>
            <div class="grid place-content-center w-8 aspect-square v-btn v-btn--rounded bg-primary">
                <v-tooltip v-if="!scrolled || active" location="top" text="Kontakt">
                    <template v-slot:activator="{ props }">
                        <button v-bind="props" @click="emit('openOffcanvas')">
                            <Chat class="w-5" />
                        </button>
                    </template>
                </v-tooltip>
                <button v-else type="button" @click="active = true" aria-label="Iconbar aufmachen">
                    <PlusIcon class="w-7" />
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

import { PlusIcon, MinusIcon } from "@heroicons/vue/24/outline";

import { BookOpenIcon } from "@heroicons/vue/24/outline";
import Hause from "@/icons/Hause.vue";
import Profile from "@/icons/Profile.vue";
import Chat from "@/icons/Chat.vue";

import wishlist from "@/stores/wishlist";
import { openLogin } from "@/stores/login";
import { computed, ref } from "vue";
import { useUserStore } from "@/stores/userStore";

const userStore = useUserStore();
const isLoggedIn = computed(() => userStore.isLoggedIn);

const emit = defineEmits(["openOffcanvas"]);

const props = defineProps<{
    auth: boolean;
    scrolled: boolean;
}>();

const active = ref(false);

</script>