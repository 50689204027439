<template>
  <section :class="showFilter ? 'z-[200]' : 'z-20'" class="full hero full-end layout lg:not-layout">
    <div
      class="full full-end lg:full relative lg:full-end lg:z-20 aspect-[3.7] lg:aspect-[5.7]"
    >
      <div
        class="absolute full h-full full-end top-0 left-0 w-full after:absolute after:w-full after:rounded-lg after:h-full after:top-0 after:left-0 after:bg-gradient-to-t after:from-black after:opacity-50 after:z-5"
      >
        <Image
          v-if="img && img.length > 0 && img[0].url"
          :src="img[0].url"
          :srcset="img[0].srcset"
          class="w-full h-full opacity-90 rounded-lg object-cover"
          :alt="img[0].alt ? img[0].alt : ''"
          :title="img[0].title ? img[0].title : ''"
          :img="img[0]"
          :isNotLazy="true"
        />
      </div>
      <div
        class="relative z-10 text-white text-center h-full flex items-center justify-center py-12 md:py-20 lg:py-24 xl:py-10"
      >
        <h1 v-if="heading" class="h1">{{ heading }}</h1>
      </div>
    </div>
    <button
      type="button"
      @click="setState()"
      class="py-3 bg-primary rounded-b-3xl flex justify-center gap-3 items-center lg:hidden"
    >
      <span class="w-6 block">
        <FilterIcon class="w-6" />
      </span>
      <span> Häuser finden </span>
    </button>
    <div
      :class="{ 'left-0 active-offcanvas': showFilter, '-left-full top-0': '-top-full' }"
      class="h-dvh rounded-b-lg w-full fixed top-0 z-[100] lg:static lg:h-max lg:-mt-2"
    >
      <button
        @click="setState()"
        type="button"
        :class="{ 'after:opacity-100': showAnimation, 'opacity-0': !showAnimation }"
        class="absolute w-full h-dvh top-0 left-0 bg-[#000] bg-opacity-50 transition-opacity duration-300 z-0 cursor-default lg:hidden"
      ></button>
      <div
        :class="showFilter && showAnimation ? 'top-0' : '-top-full'"
        class="h-dvh overflow-y-auto px-4 py-4 bg-gray w-full lg:bg-[transparent] full full-end layout md:pt-10 lg:px-0 lg:py-0 z-10 flex flex-col items-center absolute transition-[top] duration-500 lg:h-max lg:static"
      >
        <div class="w-full rounded-b-lg lg:bg-gray lg:full lg:full-end">
          <div
            style="--gap: 1rem"
            class="px-4 space-y-10 w-full pb-3 lg:relative lg:z-10 lg:rounded-b-lg lg:px-6 lg:pb-6 lg:pt-8 xl:pt-12 2xl:pt-13 lg:space-y-0 lg:gap-[var(--gap)] xl:layout"
          >
            <div class="w-max ml-auto mb-8 mt-6 lg:hidden">
              <button @click="setState()" type="button">
                <Close class="w-4" />
              </button>
            </div>
            <div
              class="space-y-4 md:flex md:space-y-0 md:gap-[var(--gap)] md:flex-wrap md:justify-start lg:justify-center xl:layout-content-start xl:layout-content-end 2xl:full 2xl:full-end"
            >
              <!-- render all options -->
              <template v-if="options">
                <template v-for="(option, index) in options">
                  <!-- if option type is select -->
                  <div
                    @change="HandleChange"
                    v-if="option.optionType == 'select'"
                    :class="`md:w-[calc(50%-var(--gap))] lg:w-[calc(33%-var(--gap))] 2xl:w-[calc(16.5%-var(--gap))] ${
                      index > 4 ? (isOpen ? '' : 'lg:hidden') : ''
                    }`"
                  >
                    <!-- set heading -->
                    <h3
                      class="hidden lg:block mb-4 uppercase"
                      v-text="option.heading"
                    ></h3>
                    <div class="bg-white h-max rounded-full">
                      <!-- check, are there values. if yes, render a select input -->
                      <!-- if value is a reference, id is set as value -->
                      <!-- update active options store on change via v-model -->
                      <v-select
                        v-if="option.values && option.values.length > 0"
                        :multiple="true"
                        v-model="active_options[option.optionhandle]['value']"
                        menu-icon="mdi-chevron-down"
                        hide-details
                        :label="option.heading"
                        :disabled="active_options[option.optionhandle]['disabled']"
                        :items="
                          option.values.map((entry) => {
                            return entry.isreference
                              ? { title: entry.optionValue, value: entry.valueId }
                              : entry.optionValue;
                          })
                        "
                      >
                        <template v-slot:append-inner="{ isFocused }">
                          <div
                            class="w-7 transition-transform duration-300"
                            :class="isFocused.value ? 'rotate-180' : 'rotate-0'"
                          >
                            <ChevronDownIcon class="w-full text-primary" />
                          </div>
                        </template>
                        <template v-slot:chip="{ item, index }">
                          <span class="bg-gray rounded-full px-2" v-if="index < 1">{{
                            item.title
                          }}</span>
                          <span
                            v-if="index === 1"
                            class="text-grey text-xl align-self-center text-xs bg-gray rounded-full px-2"
                          >
                            +{{ active_options[option.optionhandle]["value"].length - 1 }}
                          </span>
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <!-- if option type is radio -->
                  <div
                    v-if="option.optionType == 'radio'"
                    :class="`md:w-[calc(50%-var(--gap))] lg:w-[calc(33%-var(--gap))] 2xl:w-[calc(16.5%-var(--gap))] ${
                      index > 4 ? (isOpen ? '' : 'hidden') : ''
                    }`"
                  >
                    <h3
                      class="hidden lg:block mb-4 uppercase"
                      v-text="option.heading"
                    ></h3>
                    <div class="h-max rounded-full">
                      <v-select
                        v-if="option.values && option.values.length > 0"
                        :multiple="false"
                        v-model="active_options[option.optionhandle]['value']"
                        menu-icon="mdi-chevron-down"
                        hide-details
                        @update:modelValue="focusButton"
                        :label="option.heading"
                        :items="
                          option.values.map((entry) => {
                            return entry.isreference
                              ? { title: entry.optionValue, value: entry.valueId }
                              : entry.optionValue;
                          })
                        "
                      >
                        <template v-slot:append-inner="{ isFocused }">
                          <div
                            class="w-7 transition-transform duration-300"
                            :class="isFocused.value ? 'rotate-180' : 'rotate-0'"
                          >
                            <ChevronDownIcon class="w-full text-primary" />
                          </div>
                        </template>
                        <template v-slot:chip="{ item, index }">
                          <span class="bg-gray rounded-full px-2">
                            {{ extractFirstWord(item.title) }}
                          </span>
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <!-- if option type is range -->
                  <div
                    v-if="option.optionType == 'range'"
                    style="--marginX: 0.4rem"
                    :class="`md:w-[calc(100%-var(--gap)-var(--marginX))] lg:mx-[var(--marginX)] lg:w-[calc(50%-var(--gap)-var(--marginX))] 2xl:w-[calc(25%-var(--gap)-var(--marginX))] ${
                      index > 4 ? (isOpen ? '' : 'hidden') : ''
                    }`"
                  >
                    <div>
                      <!-- set heading -->
                      <h3 class="uppercase">{{ option.heading }}</h3>
                      <div
                        style="--v-theme-surface-variant: 227, 6, 19"
                        class="bg-white py-2 px-3 rounded-full mt-6 filter-select"
                      >
                        <!-- type range must have min, max and property values -->
                        <!-- update active options store on change via v-model -->
                        <v-range-slider
                          :step="
                            option.values.find((entry) => entry.valueAttribute == 'step')
                              .optionValue
                          "
                          v-model="active_options[option.optionhandle]['value']"
                          :disabled="active_options[option.optionhandle]['disabled']"
                          color="primary"
                          track-color="#000"
                          :min="
                            option.values.find((entry) => entry.valueAttribute == 'min')
                              .optionValue
                          "
                          :max="
                            option.values.find((entry) => entry.valueAttribute == 'max')
                              .optionValue
                          "
                          :rounded="true"
                          :show-ticks="false"
                          :hide-details="true"
                          thumb-label="always"
                          thumb-color="primary"
                          strict
                        >
                          <template v-slot:thumb-label="{ modelValue }">
                            <span class="flex w-max"
                              >{{ modelValue }}
                              {{
                                option.values.find(
                                  (entry) => entry.valueAttribute == "property"
                                ).optionValue
                              }}</span
                            >
                          </template>
                        </v-range-slider>
                      </div>
                      <div class="flex items-center text-xs justify-between mt-3">
                        <p>
                          {{
                            option.values.find((entry) => entry.valueAttribute == "min")
                              .optionValue
                          }}
                          {{
                            option.values.find(
                              (entry) => entry.valueAttribute == "property"
                            ).optionValue
                          }}
                        </p>
                        <p>
                          {{
                            option.values.find((entry) => entry.valueAttribute == "max")
                              .optionValue
                          }}
                          {{
                            option.values.find(
                              (entry) => entry.valueAttribute == "property"
                            ).optionValue
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template v-else v-for="child in 5">
                <div
                  class="w-full h-12 skeleton my-1.5 rounded-full lg:max-w-[30%]"
                ></div>
              </template>
            </div>
            <div
              class="flex justify-between items-center py-6 font-bold lg:gap-10 lg:py-2 lg:justify-start lg:!mt-1 lg:!full lg:w-max"
            >
              <button class="opacity-80 hidden lg:block" @click="toggleFilter">
                {{ isOpen ? "- weniger Filter anzeigen" : "+ weitere Filter anzeigen" }}
              </button>
              <button
                type="button"
                aria-label="Filter zurücksetzen"
                class="lg:opacity-80"
                @click="ResetFilter()"
              >
                Filter zurücksetzen
              </button>
            </div>
          </div>
        </div>
        <!-- trigger button. it updates the products list -->
        <v-btn
          id="submitHouseFinder"
          :disabled="total == 0"
          @click="SetProducts();"
          type="button"
          :loading="loading"
          class="py-3 bg-primary !font-normal w-[calc(100%-(var(--padding-x-lg)*2))] rounded-lg focus:outline-none lg:!rounded-t-none lg:rounded-b-xl lg:w-max lg:!px-12 mx-auto"
        >
          {{ total }} Meisterstücke gefunden
        </v-btn>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import type { FilterOption } from "@/types";
import { ChevronDownIcon } from "@heroicons/vue/24/outline";

import Close from "@/icons/Close.vue";
import FilterIcon from "@/icons/Filter.vue";

import {
  products,
  settings,
  active_query,
  totalPreview,
  total,
  activePage,
  loading,
} from "../store";
import client from "@/api/craft";

import { onMounted, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import type { Image } from "@/types/elements";

const props = defineProps<{
  heading: string;
  img: Image[];
}>();

const router = useRouter();
const route = useRoute();

const showFilter = ref(false);
const showAnimation = ref(false);

const options = ref<FilterOption[] | null>(null);
const active_options = ref<activeOptions>({});
const timeOut = ref<any>(null);

let initial = true;

type activeOptions = {
  [key: string]: {
    type: string;
    handle: string;
    value: string[];
    disabled?: boolean;
  };
};

// set an offcanvas state for the mobile
function setState() {
  if(window.innerWidth < 1024) {
    if (showFilter.value == false) {
      showFilter.value = true;
      showAnimation.value = true;
    } else {
      const menu = document.querySelector(".v-menu");

      if (menu) {
        menu.remove();
      }

      showAnimation.value = false;
      setTimeout(() => (showFilter.value = false), 200);
    }
  }
}

onMounted(() => {
  InitialSetup();
});

/* 
  access all options from the api
  map all options to the active options store with relevant values
*/

async function InitialSetup() {
  const res = await client.GetOptions();
  options.value = res.data.globalSet.hausFilter;
  res.data.globalSet.hausFilter.forEach((entry) => {
    active_options.value[entry.optionhandle] = {
      type: entry.optionType,
      handle: entry.optionhandle,
      value: [],
    };
  });

  let submitButton = document.getElementById('submitHouseFinder');
  let element = document.getElementById('houseResults');

  if(submitButton && element) {
    submitButton.addEventListener('click', () => {
      if(window.innerWidth < 1024) {
        setState();
      }
      setTimeout(() => {
        element.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
      }, 10);
      });
  }

  ResetFilter();
  // access the query from the url
  const urlParams = new URLSearchParams(window.location.search);
  for (let [key, value] of urlParams.entries()) {
    // check if the key is in the active options
    if (key in active_options.value) {
      // split the query values
      const queryValues = value.split(",");
      const obj = options.value.find((option) => option.optionhandle == key);

      if (obj.optionType == "range") {
        active_options.value[key].value = queryValues;
      } else {
        // check is value valid
        active_options.value[key].value = queryValues
          .map((entry) => {
            const value = obj.values.find(
              (value) => value.optionValue.trim() == entry || value.valueId == +entry
            );

            // if is reference return the id
            if (value && value.isreference) {
              return value.valueId.toString();
            } else {
              return value?.optionValue;
            }
            // filter out undefined values
          })
          .filter((value) => value != undefined);
      }
    }
  }

  // set initial products
  SetProducts();

  HandleChange();
  GetNumber();
}

// get the number of products
async function GetNumber() {
  const res = await client.GetAll(active_query.value);
  totalPreview.value = res.data.houseEntries.length;
  total.value = res.data.houseEntries.length;
}

// watch for changes in the active options and update the number of products
watch(active_options.value, (value) => {
  if (!initial) {
    HandleChange();
  }
});

function checkMobileOrientation() {
  if(window.innerWidth < 768) {
    return window.innerWidth > window.innerHeight;
  } else {
    return false;
  }
}

function HandleChange() {
  // check if housestyle is containing specific ids and disable price and houseType
  checkForDisableOptions();
  SetQuery();
  // clear the timeout if new changes are made
  clearTimeout(timeOut.value);

  loading.value = true;

  // set a timeout to prevent multiple api calls
  timeOut.value = setTimeout(() => {
    client.GetAll(active_query.value).then((res) => {
      // check if there are errors
      if ("errors" in res) {
        total.value = 0;
      } else {
        // set the number of products
        total.value = res.data.houseEntries.length;

        SetProducts();
      }
    });
  }, 2000);
}

function checkForDisableOptions() {
  let houseStyles = active_options.value.houseStyles.value;
  let houseTypes = active_options.value.houseTypes;
  let prices = active_options.value.prices;

  if ((houseStyles.includes('10853') || houseStyles.includes('10854') || houseStyles.includes('10855'))
      && !houseTypes.disabled && !prices.disabled) {
    // disable houseTypes and prices if houseStyles is containing '10854'
    houseTypes.disabled = true;
    prices.disabled = true;


    // reset values
    active_options.value.houseTypes.value = [];
    resetRangeSlider('prices');

  } else if ((!houseStyles.includes('10853') && !houseStyles.includes('10854') && !houseStyles.includes('10855'))
      && houseTypes.disabled && prices.disabled) {
    // enable houseTypes and prices if houseStyles is not containing '10854'
    houseTypes.disabled = false;
    prices.disabled = false;
  }
}

function resetRangeSlider(optionHandle: string) {
  const option = options.value.find((entry) => entry.optionhandle === optionHandle);
  if (option && option.optionType === 'range') {
    const min = option.values.find((entry) => entry.valueAttribute === 'min').optionValue;
    const max = option.values.find((entry) => entry.valueAttribute === 'max').optionValue;

    active_options.value[optionHandle].value = [min, max];
  }
}

// update products store
function SetProducts() {
  const values = SetQuery();

  let search = {};

  // generate UrlQuery from active options
  if (Object.keys(values).length > 0) {
    Object.keys(values).forEach((key) => {
      if (values[key].type == "radio") {
        search[key] = values[key].value;
      } else {
        search[key] = values[key].value
          .map((entry: string) => entry.toString().trim())
          .join(",");
      }
    });
  }
  // save page on initial load
  if (initial) {
    //router.push({ query: { ...route.query, ...search } });
  } else {
    activePage.value = 1;
    router.push({ query: { ...search } });
  }

  // get the first products page with the active query
  client
    .Pagination(
      active_query.value,
      settings.perPage,
      route.query.page ? (parseInt(route.query.page as string) - 1) * settings.perPage : 0
    )
    .then((res) => {
      products.value = res.data.houseEntries;
      loading.value = false;
      // set initial to false
      if (initial) {
        initial = false;
      }
    });
}

function filterObject(obj: any, predicate: any) {
  return Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {});
}

function SetQuery() {
  // get objects with values
  const values = filterObject(active_options.value, (value) => value.value.length > 0);

  // format the query
  // query is a store, because it is used in products component for the pagination
  active_query.value = Object.keys(values)
    .map((key) => {
      if (values[key].type == "select" && values[key].handle == "numberOfFloors") {
        let numberOfFloors = [];

        values[key].value.forEach((val) => {
          if (val.indexOf("-") > -1) {
            let floors = val.split("-");

            for (let i = floors[0]; i <= floors[1]; i++) {
              if (!numberOfFloors.includes(i)) {
                numberOfFloors.push(i);
              }
            }
          } else if (val.indexOf("+") > -1) {
            let floors = val.split("+");

            for (let i = floors[0]; i <= 15; i++) {
              if (!numberOfFloors.includes(i)) {
                numberOfFloors.push(i);
              }
            }
          } else if (!numberOfFloors.includes(val)) {
            numberOfFloors.push(val);
          }
        });

        return `${key}: [${numberOfFloors.map((entry) => `"${entry}"`)}]`;
      } else if (values[key].type == "select") {
        // example: houseTypes: ["1055"]
        return `${key}: [${values[key].value.map((entry) => `"${entry}"`)}]`;
      } else if (values[key].type == "radio") {
        return `${key}: ["${values[key].value}"]`;
      } else if (values[key].type == "range" && values[key].handle == "prices") {
        //ToDo Release 2.0: Remove new price fields and use right "prices" @Vue Dev
        let expansionStagesType = 1;
        let optValues = options.value.find((entry) => entry.optionhandle == key).values;
        let rangeMin = optValues.find((entry) => entry.valueAttribute == "min")
          .optionValue;
        let rangeMax = optValues.find((entry) => entry.valueAttribute == "max")
          .optionValue;

        if (rangeMin == values[key].value[0] && rangeMax == values[key].value[1]) {
          return "";
        }

        let expansionStages = options.value.find(
          (entry) => entry.optionhandle == "expansionStages"
        ).values;
        let selected = expansionStages.find(
          (entry) => entry.valueId == active_options.value.expansionStages.value[0]
        );

        if (selected === undefined) {
          selected = expansionStages.find(
            (entry) => entry.valueId == active_options.value.expansionStages.value
          );
        }

        if (selected.optionValue.indexOf("Ausbau") == -1) {
          expansionStagesType = 2;
        }

        let from = "pricetyp" + expansionStagesType + "from";
        let to = "pricetyp" + expansionStagesType + "to";

        return `${from}: ["and", ">= ${values[key].value[0]}"] ${to}: ["and", "<= ${values[key].value[1]}"]`;
      } else if (values[key].type == "range") {
        let optValues = options.value.find((entry) => entry.optionhandle == key).values;
        let rangeMin = optValues.find((entry) => entry.valueAttribute == "min")
          .optionValue;
        let rangeMax = optValues.find((entry) => entry.valueAttribute == "max")
          .optionValue;

        if (rangeMin == values[key].value[0] && rangeMax == values[key].value[1]) {
          return "";
        }

        // example: totalLivingArea: ["and", "<= 200", ">= 10"]
        return `${key}: ["and", "<= ${values[key].value[1]}", ">= ${values[key].value[0]}"]`;
      }
    })
    .join(", ");

  // return not empty values
  return values;
}

const isOpen = ref(false);

const toggleFilter = () => {
  isOpen.value = !isOpen.value;
};

function extractFirstWord(text: string) {
  let words = text.split(" ");
  return words[0];
}

function ResetFilter() {
  options.value.forEach((option) => {
    if (option.optionType == "radio") {
      active_options.value[option.optionhandle].value = option.values[0].valueId;
    } else if (option.optionType == "range") {
      const min = option.values.find((entry) => entry.valueAttribute == "min")
        .optionValue;
      const max = option.values.find((entry) => entry.valueAttribute == "max")
        .optionValue;

      active_options.value[option.optionhandle] = {
        type: option.optionType,
        handle: option.optionhandle,
        value: [min, max],
      };
    } else {
      active_options.value[option.optionhandle].value = [];
    }
    if (active_options.value[option.optionhandle].disabled) {
      active_options.value[option.optionhandle].disabled = false;
    }
  });
}

function focusButton() {
  document.getElementById("submitHouseFinder").focus();
}
</script>
